// 
// _table.scss
// 

.table {
  tr th:nth-child(1),
  tr th:nth-last-child(1),
  tr td:nth-child(1),
  tr td:nth-last-child(1) {
    padding-left: 20px;
    padding-right: 20px;
  }
  th, td {
      font-weight: $font-weight-normal;
      font-size: 12px;
      color: $gray-700;
  }
  td {
      color: $gray-900;
      font-size: 16px;
      line-height: 16px;
      padding-top: 20px;
      padding-bottom: 20px;
  }
  small {
    font-size: 12px;
    color: $gray-700;
  }
  .order,
  .react-bootstrap-table-sort-order {
    display: inline-block;
    position: relative;
    top: 5px;
    padding-left: 12px;
  }
  .react-bootstrap-table-sort-order.dropup {
    bottom: -18px;
  }
  // th .dropdown,
  // th .dropup,
  // th .caret {
  //   display:inline-block;
  // }
  th .caret::after,
  th .caret::before {
    font-family: 'CredRead' !important;
    position: absolute;
    top:-15px;
    font-weight: 200;
    color: $gray-400;
    font-size:10px;
  }
  th .caret::after {
    content: "\e912";
  }
  th .caret::before {
    content: "\e913";
  }
  th[aria-label~="desc"] .caret::after {
    content: "\e912";
    color: $gray-600;
  }
  th[aria-label~="asc"] .caret::before {
    content: "\e913";
    color: $gray-600;
  }
  th[aria-label~="JobStats"] {
    opacity:0;
  }
  .border-dashed {
    border-right: 1px dashed $gray-400;
  }
  .bg-light {
    background-color: $gray-300;
  }

}

//Table centered
.table-centered {
  td,th {
      vertical-align: middle !important;
  }
}

.table-nowrap {
  th, td {
    white-space: nowrap;
  }
}

.table-bordered {
  border: $table-border-width solid $table-border-color;

  th,
  td {
    border: $table-border-width solid $table-border-color;
  }
}


.table-dark>:not(:last-child)>:last-child>*{
  border-bottom-color:  $table-dark-border-color;
}