.text-primary {
    color: $primary !important;
}
.text-gray-2 {
    color: #FAFAFA;
}
.bg-gray-2 {
    background-color: #FAFAFA;
}
.text-gray-6 {
    color: #C4C4C4;
}
.bg-gray-6 {
    background-color: #C4C4C4;
}
.text-gray-7 {
    color: #8C8C8C;
}
.bg-gray-7 {
    background-color: #8C8C8C;
}
.bg-primary-light {
    background-color: #DEF6FC !important;
}
.bg-primary {
    background-color: $primary !important;
}