//
// Fonts
//

// DM Sans - Google Font
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");

.font-weight-regular {
  font-weight: 400;
}
.font-weight-medium {
  font-weight: 500;
}
.font-weight-bold {
  font-weight: 700;
}
h1, h2, h3, h4, h5, h6 {
  color: $gray-900;
}
h1 { font-size: 30px;}
h2 { font-size: 24px;}
h3 { font-size: 20px;}
h4 { font-size: 18px;}