@font-face {
  font-family: 'CredRead';
  src:  url('fonts/CredRead.eot?z2dzw3');
  src:  url('fonts/CredRead.eot?z2dzw3#iefix') format('embedded-opentype'),
    url('fonts/CredRead.ttf?z2dzw3') format('truetype'),
    url('fonts/CredRead.woff?z2dzw3') format('woff'),
    url('fonts/CredRead.svg?z2dzw3#CredRead') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'CredRead' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.credread-expanduser-icon:before {
  content: "\e919";
}
.credread-successtik-icon:before {
  content: "\e915";
  color: #8fc564;
}
.credread-redclose-icon:before {
  content: "\e916";
  color: #f52245;
}
.credread-camera-icon:before {
  content: "\e917";
}
.credread-upload-icon:before {
  content: "\e918";
}
.credread-tick-icon:before {
  content: "\e914";
  color: #6fa949;
}
.credread-sort-up-icon .path1:before {
  content: "\e912";
  color: rgb(81, 81, 81);
}
.credread-sort-up-icon .path2:before {
  content: "\e913";
  margin-left: -0.7392578125em;
  color: rgb(196, 196, 196);
}
.credread-arrowdown-icon:before {
  content: "\e900";
}
.credread-briefcase-icon:before {
  content: "\e901";
}
.credread-cog-icon:before {
  content: "\e902";
}
.credread-cross-icon:before {
  content: "\e903";
}
.credread-dashboard-icon:before {
  content: "\e904";
}
.credread-duplicate-icon:before {
  content: "\e905";
}
.credread-envelop-icon:before {
  content: "\e906";
}
.credread-file-icon:before {
  content: "\e907";
}
.credread-filter-icon:before {
  content: "\e908";
}
.credread-hide-icon:before {
  content: "\e909";
}
.credread-lock-icon:before {
  content: "\e90a";
}
.credread-mappin-icon:before {
  content: "\e90b";
}
.credread-message-icon:before {
  content: "\e90c";
}
.credread-plus-icon:before {
  content: "\e90d";
}
.credread-questionmark-icon:before {
  content: "\e90e";
}
.credread-user-icon:before {
  content: "\e90f";
}
.credread-userfile-icon:before {
  content: "\e910";
}
.credread-users-icon:before {
  content: "\e911";
}
